import React, { Component } from "react";
import FormSubmitted from "../../components/FormSubmitted";
import Firebase from "../../utils/Firebase";
import '../../components/jumbotron.css';
import Wrapper from "../../components/Wrapper";
import Footer from "../../components/Footer";
import ReactPixel from "react-facebook-pixel";

function CheckError(props) {
    return (
        <small className="form-text text-danger">
            {props.text}
        </small>
    );
}

class CashBuyers extends Component {

    componentDidMount() {

        ReactPixel.init("509757082914036", {}, { debug: true, autoConfig: false });
        ReactPixel.pageView();
        ReactPixel.fbq("track", "PageView");
    }

    state = {
        fname: "",
        lname: "",
        email: "",
        mobile: "",
        buyer: "",
        sf: false,
        mf: false,
        cm: false,
        twoFifty: false,
        fiveHundred: false,
        sevenFifty: false,
        oneMillion: false,
        oneMillionPlus: false,
        errorMessage: "",
        showCheckError: false,
        showSubmitted: false
    };

    onFocus = event => {

        this.setState({
            showCheckError: false,
            showSubmitted: false
        })
    }

    handleInputChange = event => {

        this.setState({ [event.target.name]: event.target.value });
        console.log([event.target.name] + " : " + event.target.value)
    }

    handleCheckBoxChange = event => {

        this.setState({ [event.target.name]: event.target.checked });
        console.log([event.target.name] + " : " + event.target.checked)
    }

    handleFormSubmit = event => {
        event.preventDefault();

        event.target.className += " was-validated";

        if (event.target.checkValidity()) {


            if (!this.state.sf && !this.state.mf && !this.state.cm) {

                this.setState({
                    errorMessage: "Please select at least one property type.",
                    showCheckError: true
                })
                return;
            }
            else if (!this.state.twoFifty && !this.state.fiveHundred &&
                !this.state.sevenFifty && !this.state.oneMillion && !this.state.oneMillionPlus) {

                this.setState({
                    errorMessage: "Please select at least one investment budget.",
                    showCheckError: true
                })
                return;
            }

            Firebase
                .firestore()
                .collection('Buyers')
                .add({
                    firstName: this.state.fname,
                    lastName: this.state.lname,
                    email: this.state.email,
                    mobile: this.state.mobile,
                    buyer: this.state.buyer,
                    singleFamily: this.state.sf,
                    multiFamily: this.state.mf,
                    commercial: this.state.cm,
                    twoFifty: this.state.twoFifty,
                    fiveHundred: this.state.fiveHundred,
                    sevenFifty: this.state.sevenFifty,
                    oneMillion: this.state.oneMillion,
                    oneMillionPlus: this.state.oneMillionPlus
                })
                .then(() => {

                    this.setState({
                        fname: "",
                        lname: "",
                        email: "",
                        mobile: "",
                        buyer: "",
                        sf: false,
                        mf: false,
                        cm: false,
                        twoFifty: false,
                        fiveHundred: false,
                        sevenFifty: false,
                        oneMillion: false,
                        oneMillionPlus: false,
                        errorMessage: "",
                        showCheckError: false,
                        showSubmitted: true
                    })
                })

            this.refs["sf"].checked = false;
            this.refs["mf"].checked = false;
            this.refs["cm"].checked = false;
            this.refs["twoFifty"].checked = false;
            this.refs["fiveHundred"].checked = false;
            this.refs["sevenFifty"].checked = false;
            this.refs["oneMillion"].checked = false;
            this.refs["oneMillionPlus"].checked = false;

            event.target.className -= " was-validated";

            ReactPixel.trackCustom('Cash_Buyers_Form');
        }
    }

    render() {
        return (
            <Wrapper>

                <div className="container-fluid">

                    <div className="row justify-content-md-center mt-4 mb-2">
                        <h1>Get On Our Buyers List!</h1>
                    </div>

                    <div className="row justify-content-md-center mb-4">

                        <div className="col-md-6 offset-md-3" className="formBackground box">

                            <div className="mb-3">
                                {this.state.showSubmitted ? <FormSubmitted /> : null}
                            </div>

                            <form className="needs-validation" noValidate onSubmit={this.handleFormSubmit}>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <input type="text"
                                            name="fname"
                                            value={this.state.fname}
                                            onChange={this.handleInputChange}
                                            onFocus={this.onFocus}
                                            className="form-control"
                                            id="inputFirstName"
                                            placeholder="First Name"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            Please provide your first name.
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input type="text"
                                            name="lname"
                                            value={this.state.lname}
                                            onChange={this.handleInputChange}
                                            onFocus={this.onFocus}
                                            className="form-control"
                                            id="inputLastName"
                                            placeholder="Last Name"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            Please provide your last name.
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <input type="email"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleInputChange}
                                            onFocus={this.onFocus}
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            placeholder="E-mail Address"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            Please provide your email address.
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <input type="text"
                                            name="mobile"
                                            value={this.state.mobile}
                                            onChange={this.handleInputChange}
                                            onFocus={this.onFocus}
                                            className="form-control"
                                            id="inputMobile"
                                            placeholder="Mobile Number"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            Please provide your mobile number.
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <select className="custom-select" required
                                            name="buyer"
                                            value={this.state.buyer}
                                            onChange={this.handleInputChange}
                                            onFocus={this.onFocus}
                                            className="form-control">
                                            <option value="">What kind of buyer are you?</option>
                                            <option value="Fix / Flip">Fix / Flip</option>
                                            <option value="Fix / Hold">Fix / Hold</option>
                                            <option value="Both Flip and Hold">Both Flip and Hold</option>
                                            <option value="New place">New place to live in</option>
                                        </select>
                                        <div className="invalid-feedback">
                                            Select an option from above.
                                        </div>
                                    </div>
                                </div>

                                <p>What investments are you interested in seeing when they become available? <strong>(mark all that apply)</strong></p>

                                <div className="form-row">

                                    <div className="form-group col-md-5">
                                        <strong>Property Type:</strong>
                                        <div className="form-check">
                                            <input className="checkbox" type="checkbox"
                                                name="sf"
                                                ref="sf"
                                                onChange={this.handleCheckBoxChange}
                                                onFocus={this.onFocus}
                                                id="sf" />
                                            <label className="form-check-label" htmlFor="sf"> Single Family</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="checkbox" type="checkbox"
                                                name="mf"
                                                ref="mf"
                                                onChange={this.handleCheckBoxChange}
                                                onFocus={this.onFocus}
                                                id="mf" />
                                            <label className="form-check-label" htmlFor="mf">Multi-Family</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="checkbox" type="checkbox"
                                                name="cm"
                                                ref="cm"
                                                onChange={this.handleCheckBoxChange}
                                                onFocus={this.onFocus}
                                                id="cm" />
                                            <label className="form-check-label" htmlFor="cm"> Commercial</label>
                                        </div>
                                    </div>

                                    <div className="form-group col-md-7">
                                        <strong>Investment Budget:</strong>
                                        <div className="form-check">
                                            <input className="checkbox" type="checkbox"
                                                name="twoFifty"
                                                ref="twoFifty"
                                                onChange={this.handleCheckBoxChange}
                                                onFocus={this.onFocus}
                                                id="250k" />
                                            <label className="form-check-label" htmlFor="250k"> Upto $250k</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="checkbox" type="checkbox"
                                                name="fiveHundred"
                                                ref="fiveHundred"
                                                onChange={this.handleCheckBoxChange}
                                                onFocus={this.onFocus}
                                                id="500k" />
                                            <label className="form-check-label" htmlFor="500k">Upto $500k</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="checkbox" type="checkbox"
                                                name="sevenFifty"
                                                ref="sevenFifty"
                                                onChange={this.handleCheckBoxChange}
                                                onFocus={this.onFocus}
                                                id="750k" />
                                            <label className="form-check-label" htmlFor="750k">Upto $750k</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="checkbox" type="checkbox"
                                                name="oneMillion"
                                                ref="oneMillion"
                                                onChange={this.handleCheckBoxChange}
                                                onFocus={this.onFocus}
                                                id="1m" />
                                            <label className="form-check-label" htmlFor="1m">Upto $1m</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="checkbox" type="checkbox"
                                                name="oneMillionPlus"
                                                ref="oneMillionPlus"
                                                onChange={this.handleCheckBoxChange}
                                                onFocus={this.onFocus}
                                                id="1m Plus" />
                                            <label className="form-check-label" htmlFor="1m Plus">Over $1m Plus</label>
                                        </div>
                                    </div>
                                    <div className="mb-4 ml-2">
                                        {this.state.showCheckError ? <CheckError text={this.state.errorMessage} /> : null}
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-custom">
                                    Get on our exclusive Network!
                                </button>
                            </form>
                        </div>

                        <div className="col-md-6 offset-md-3" className="right-no-white-box">

                            <p>We specialize in locating deeply discounted and distressed real estate deals and wholesaling them to rehabbers, landlords,
                                owner occupants, and/or remodeling them and flipping them to retail buyers for huge profits.</p>

                            <p>All the above strategies require a steady cashflow in order to keep the deals moving forward in order to make offers,
                                purchase and rehab these properties.</p>

                            <p>We're here to network with those that qualify and need a partner that find deals that don't waste their time.</p>

                            <p>Once we secure a new property that fits our criteria, we will immediately notify our Exclusive Buyers of the deal.
                            First we notify those who have provided us with specific information on the types of properties they’re looking for,
                            then we notify those buyers on the Exclusive Buyers email list.</p>

                            <p>If you are interested in getting on our Exclusive Buyers list (it’s free!).
                                You will be notified of each new discount home or investment property available.</p>
                        </div>
                    </div>
                </div>

                <Footer />
            </Wrapper >
        )
    }
}

export default CashBuyers;
