import React from "react";

function Footer() {
    return (

        <div className="container-fluid bg-light">

            <div className="row justify-content-md-center">
                <div className="col-md-6 offset-md-3" className="right-no-white-box">
                    <h4>Cities We Buy In:</h4>
                    <li>Atlanta</li>
                    <li>All surrounding cities</li>
                </div>
                <div className="col-md-6 offset-md-3" className="right-no-white-box">
                    <h4>Legacy Lake Property Group, LLC</h4>
                    <li>(770) 284-0236</li>
                    <li>info@LegacyLakeProperties.com</li>
                    <li>Monday – Friday: 8:00 AM – 10:00 PM</li>
                </div>
            </div>

            <div className="row justify-content-between bg-dark">
                <div className="col text-white pt-4">
                    <pre><a href="/termsOfService" style={{ color: "white" }}>Terms of Service</a>   |   <a href="/privacyPolicy" style={{ color: "white" }}>Privacy Policy</a></pre>
                </div>
            </div>
            <div className="row justify-content-between bg-dark">
                <div className="col text-white">
                    <pre>© <a href="/" style={{ color: "white" }}>Legacy Lake Property Group, LLC</a>   |   <a style={{ color: "white" }}> All Rights Reserved</a>   |   <a style={{ color: "white" }}> Designed by Lj Interactive Media, LLC</a></pre>
                </div>
            </div>
        </div>
    );
}

export default Footer;
