import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBa3WZo3A3h6gw2TPAAleY1M7w26du85Ew",
    authDomain: "legacy-lake-property-group.firebaseapp.com",
    databaseURL: "https://legacy-lake-property-group.firebaseio.com",
    projectId: "legacy-lake-property-group",
    storageBucket: "legacy-lake-property-group.appspot.com",
    messagingSenderId: "472935174639",
    appId: "1:472935174639:web:e550118c4ca87d6ed2261a"
};
firebase.initializeApp(firebaseConfig);

export default firebase;
